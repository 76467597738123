<template>
  <section>
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <b-row>
          <b-col md="6">
            <b-card align="center">
              <b-avatar size="100" class="mb-2 mt-5" variant="light">
                <feather-icon icon="UserIcon" size="60" class="text-dark" />
              </b-avatar>

              <h3>Registra nuovo Utente</h3>
              <b-card-text
                >Inserisci le informazioni in pochi e semplici step per creare
                un nuovo account utente.</b-card-text
              >

              <!-- buttons -->
              <b-button
                class="mt-4"
                variant="primary"
                @click="$router.push('/user_register')"
              >
                Registra
                <feather-icon class="ml-1" icon="ArrowRightIcon" />
              </b-button>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card align="center">
              <b-avatar
                size="100"
                class="mb-2 mt-5"
                variant="light"
                alt="basic svg img"
              >
                <feather-icon icon="SearchIcon" size="60" class="text-dark" />
              </b-avatar>

              <h3>Cerca Cliente</h3>
              <b-card-text>
                Cerca un utente e accedi alla Scheda Cliente
              </b-card-text>

              <!-- buttons -->
              <b-button
                class="mt-4"
                variant="primary"
                @click="$router.push('/customer_search')"
              >
                Cerca
                <feather-icon class="ml-1" icon="ArrowRightIcon" />
              </b-button>
            </b-card>
          </b-col>
          <!-- <b-col md="4">
            <b-card align="center">
              <b-avatar size="100" class="mb-2 mt-5" alt="basic svg img" variant="light">
                <feather-icon icon="MessageSquareIcon" size="60" class="text-dark" />
              </b-avatar>

              <h3>Help Desk</h3>
              <b-card-text>Area di supporto tecnico per aprire un ticket e ricevere assistenza.</b-card-text>

              <b-button class="mt-4 mr-2" variant="secondary" @click="$router.push('/ticket')">
                I tuoi Ticket
                <feather-icon class="ml-1" icon="ArrowRightIcon" />
              </b-button>
              <b-button class="mt-4" variant="primary" @click="$router.push('/ticket/open')">
                Apri ticket
                <feather-icon class="ml-1" icon="ArrowRightIcon" />
              </b-button>
            </b-card>
          </b-col> -->
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->
  </section>
</template>

<script>
import {
  BAvatar,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
/* eslint-disable global-require */
export default {
  components: {
    BAvatar,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: "monthly",
      monthlyPlanShow: true,
      pricing: {},
    };
  },
  created() {
    this.$http.get("/pricing/data").then((res) => {
      this.pricing = res.data;
    });
  },
  methods: {
    tooglePlan() {
      if (this.status === "monthly") {
        this.monthlyPlanShow = true;
      } else {
        this.monthlyPlanShow = false;
      }
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
